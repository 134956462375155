import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'pageList',
  //   component: function () {
  //     return import('../views/pageList.vue')
  //   }
  // },
  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../views/home.vue')
    }
  },
  {
    path: '/home',
    name: 'home',
    component: function () {
      return import('../views/home.vue')
    }
  },
  {
    path: '/apes',
    name: 'apes',
    component: function () {
      return import('../views/apes.vue')
    }
  },
  {
    path: '/sheng',
    name: 'sheng',
    component: function () {
      return import('../views/sheng.vue')
    }
  },
  {
    path: '/task',
    name: 'task',
    component: function () {
      return import('../views/task.vue')
    }
  },
  {
    path: '/bag',
    name: 'bag',
    component: function () {
      return import('../views/bag.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
