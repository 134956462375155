import axios from "axios";

import user from './user';
import store from '../store/index';

const TELEGRAM_BOT_URL = process.env.VUE_APP_TELEGRAM_BOT_URL;

// 封装axios构造函数请求
// 1.先是自定义一个方法名
// 2.然后获取头部token值
// 3.延长器设不设置都可以
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: { 'Content-Type': ' application/x-www-form-urlencoded' }
})

// 请求拦截器
// 1.请求拦截器，在发请求之前，请求拦截器可以检测到发起请求之前需要做什么事情，把需要做的事情，放在请求拦截器之前
instance.interceptors.request.use(config => {
    // 1.这里我设置了，在请求之前，先开始进度条的动画
    // 2.返回请求数据
    console.log('axios', store.state.userData);
    if (store.state.userData && store.state.userData.Token) {
        // var address = JSON.parse(localStorage.getItem('userInfo'))['loginName'];
        var address = store.state.userData.loginName;
        if (config.url.includes("?")) {
            config.url = config.url + "&token=" + store.state.userData.Token + "&address=" + address;
        } else {
            config.url = config.url + "?token=" + store.state.userData.Token + "&address=" + address;
        }
    }
    return config

})
// 响应拦截器
// 1.相应上面的请求拦截器的需求
// 2.把上面的请求拦截器的需求全部做到
instance.interceptors.response.use(res => {
    console.log('axios.after', res);
    if (res.status == 200) {
        if (res.data.message == '登录失败') {
            user.logOut();
        }
    }
    return res.data
}, error => {
    return Promise.reject(error)
})


function getApi(url, params) {
    return instance({
        method: 'GET',
        url: '/api/a' + url,
        params: params
    })
}

function postApi(url, data) {
    return instance({
        method: 'POST',
        url: '/api/a' + url,
        data: data
    })
}

// 对外暴露前面创建的构造方法
export default {
    TELEGRAM_BOT_URL,
    getApi,
    postApi
}