import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './utils/http'
import user from './utils/user'
import "./assets/styles/main.css";

import './assets/js/telegram-web-app.js';
import TonWeb from "tonweb";

import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
Vue.use(Toast, {
  type: 'center',
  duration: 1500,
  wordWrap: true,
});

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 
// 全局过滤器的定义
Vue.filter("global_format", function (value, type = "MM-DD") {
  return moment(value).format(type);
});


Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$user = user
Vue.prototype.$tonWeb = new TonWeb();

import ucFoot from '@/components/ucFoot'
Vue.component('ucFoot', ucFoot)

window.open = (function (open) {
  return function (url, _, features) {
    return open.call(window, url, "_blank", features);
  };
})(window.open);

if (typeof String.prototype.replaceAll === "undefined") {
  String.prototype.replaceAll = function (match, replace) {
    return this.replace(new RegExp(match, 'g'), () => replace);
  }
}
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
