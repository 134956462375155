<template>
  <div>
    <footer>
      <ul class="footer-menu">
        <li v-for="(item, index) in list" :key="index" :class="index == footIndex ? 'on' : ''">
          <router-link class="item" :to="item.link">
            <span>{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </footer>

    <div class="uc-alert uc-alert-Sheng" v-show="$store.state.isShow">
      <div class="over-close" @click="$store.state.isShow = false"></div>
      <div class="box">
        <div class="title" style=" margin-top: -3rem;">
          <h2>Airdrop</h2>
          <h2>1000</h2>
        </div>
        <div class="img-box" style="margin-bottom: 2rem;">
          <img src="@/assets/images/alert-Sheng-img.png" alt="">
        </div>
        <div class="panel">
          <div class="txt">
            <h3>Airdrop 1,000 FDOG to all Telegram users</h3>
          </div>
          <ul class="list">
          </ul>
          <div class="g-operate">
            <button class="uc-btn green" hover-class="none" @click="$store.state.isShow = false">
              Put to bag
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="g-footer-space">
      <div class="footer-space"></div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'ucFoot',
  data() {
    return {
      list: [
        { name: 'home', link: '/home' },
        { name: 'task', link: '/task' },
        { name: 'bag', link: '/bag' },
        { name: 'friends', link: '/apes' },
      ],
    };
  },
  computed: {
    footIndex() {
      return this.$store.state.footIndex;
    },
  },
  beforeCreate() {
    console.log('beforeCreate: ');
    window.Telegram.WebApp.ready();
    var isConnected = localStorage.getItem('updateIsConnected');
    if (isConnected && isConnected === 'true' && isConnected !== 'undefined') {
      this.$store.dispatch('updateIsConnected', true);
    } else {
      this.$store.dispatch('updateIsConnected', false);
    }
  },
  created() {
    console.log('created: ');
    this.loadUser();
  },
  beforeMount() {
    console.log('beforeMount: ');
  },
  mounted() {
    console.log('mounted: ');
  },
  beforeUpdate() {
    console.log('beforeUpdate: ');
  },
  updated() {
    console.log('updated: ');
  },
  beforeDestroy() {
    console.log('beforeDestroy: ');
    if (this.$store.state.tonConnectUI && this.$store.state.tonConnectUI.connected) {
      this.$store.state.tonConnectUI.disconnect();
    }
  },
  destroyed() {
    console.log('destroyed: ');
  },
  methods: {

    async loadData() {
      var resp = await this.$http.postApi('/myTasks', {});
      if (resp.code == 200) {
        this.$store.dispatch('updateTaskInfo', resp.data);
      }
    },

    async loadRecord() {
      var resp = await this.$http.postApi('/myRecord', {});
      if (resp.code == 200) {
        this.$store.dispatch('updateListRecord', resp.data);
      }
    },

    async loadWallet() {
      const that = this;
      var resp = await that.$http.postApi('/MyWallet', {});
      if (resp.code == 200) {
        that.$store.dispatch('updateWalletInfo', resp.data);
      }
    },

    async loadUser() {
      // var user = this.$user.getUser();
      // if (user) {//已登录
      //   var resp = await this.$http.postApi('/checkToken', { signature: user.Token, publicAddress: user.loginName });
      //   if (resp.code == 200) {
      //     this.$user.setUser(resp.data);
      //     this.$store.dispatch('updateUserData', resp.data);
      //   } else {
      //     this.$user.logOut();
      //     this.$store.dispatch('updateUserData', null);
      //   }
      // } else {
      if (window.Telegram && window.Telegram.WebApp) {
        var resp = await this.$http.postApi('/login', { initData: window.Telegram.WebApp.initData });
        if (resp.code != 200) {
          this.$toast(resp.msg);
        } else {
          // this.$user.setUser(resp.data);
          if (resp.data) {
            this.$store.dispatch('updateUserData', resp.data);
            this.$store.dispatch('updateIsShow', resp.data.isShow);
            this.loadData();
            this.loadWallet();
            this.loadRecord();
          }
          this.loadCheckin();
        }
      }
      // }
    },
    async loadCheckin() {
      var resp = await this.$http.postApi('/myCheckin', {});
      if (resp.code == 200 || resp.code == -2) {
        this.$store.dispatch('updateCheckInfo', resp.data);
        if (resp.code == 200) {
          this.$store.dispatch('updateShowSheng', true);
          this.submitCheckIn();
        } else {
          this.$store.dispatch('updateShowCheckIn', false);
        }
      } else {
        this.$store.dispatch('updateShowCheckIn', false);
      }
    },
    async submitCheckIn() {
      var resp = await this.$http.postApi('/dailyCheckIn', {});
      if (resp.code == 200) {
        this.$toast('Sign in successful!');
        // this.$store.dispatch('updateShowSheng', false);
      } else {
        this.$toast(resp.msg);
      }
    },
  },
}
</script>