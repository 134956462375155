import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    footIndex: 0,
    userData: null,
    tonConnectUI: null,
    isConnected: false,
    isShow: false,
    showCheckIn: true,
    showSheng: false,
    checkInfo: {},
    walletInfo: {},
    listRecord: [],
    taskInfo: {},
    list: [],
    listOne: [],
    alwayShowCheckIn: false,
  },
  getters: {},
  mutations: {
    updateFootIndex(state, payload) {
      state.footIndex = payload;
    },
    updateUserData(state, payload) {
      state.userData = payload;
    },
    updateIsShow(state, payload) {
      state.isShow = payload;
    },
    updateTonConnectUI(state, payload) {
      state.tonConnectUI = payload;
    },
    updateIsConnected(state, payload) {
      state.isConnected = payload;
    },
    updateShowCheckIn(state, payload) {
      state.showCheckIn = payload;
    },
    updateShowSheng(state, payload) {
      state.showSheng = payload;
    },
    updateCheckInfo(state, payload) {
      state.checkInfo = payload;
    },
    updateAlwayShowCheckIn(state, payload) {
      state.alwayShowCheckIn = payload;
    },

    updateWalletInfo(state, payload) {
      state.walletInfo = payload;
    },
    updateListRecord(state, payload) {
      state.listRecord = payload || [];
    },
    updateTaskInfo(state, payload) {
      let act = payload || {};
      state.taskInfo = act;
      state.list = act.list || [];
      state.listOne = act.listOne || [];

    },
  },
  actions: {
    updateFootIndex({ commit }, payload) {
      commit('updateFootIndex', payload);
    },
    updateUserData({ commit }, payload) {
      commit('updateUserData', payload);
    },
    updateIsShow({ commit }, payload) {
      commit('updateIsShow', payload);
    },
    updateTonConnectUI({ commit }, payload) {
      commit('updateTonConnectUI', payload);
    },
    updateIsConnected({ commit }, payload) {
      localStorage.setItem('updateIsConnected', payload);
      commit('updateIsConnected', payload);
    },
    updateShowCheckIn({ commit }, payload) {
      commit('updateShowCheckIn', payload);
    },
    updateShowSheng({ commit }, payload) {
      commit('updateShowSheng', payload);
    },
    updateCheckInfo({ commit }, payload) {
      commit('updateCheckInfo', payload);
    },
    updateAlwayShowCheckIn({ commit }, payload) {
      commit('updateAlwayShowCheckIn', payload);
    },

    updateWalletInfo({ commit }, payload) {
      commit('updateWalletInfo', payload);
    },
    updateListRecord({ commit }, payload) {
      commit('updateListRecord', payload);
    },
    updateTaskInfo({ commit }, payload) {
      commit('updateTaskInfo', payload);
    }
  },
  modules: {
  }
})
