function setUser(data) {
    localStorage.setItem('token', data.Token);
    localStorage.setItem('userInfo', JSON.stringify(data));
}

function getUser() {
    if (localStorage.getItem('userInfo')) {
        return JSON.parse(localStorage.getItem('userInfo'));
    }
    return null;
}
function logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('updateIsConnected');
}


const hideStrSmall = (addr) => {
    if (addr && addr.length > 10) {
        return addr.substr(0, 5).toLowerCase() + "***" + addr.substr(addr.length - 4).toLowerCase();
    }
    return addr;
}


export default {
    setUser,
    getUser,
    logOut,
    hideStrSmall
}